import { Button, Heading, Pane, Text, ArrowTopRightIcon } from 'evergreen-ui';
import React from 'react';
import { IOnboardingPage } from '.';
import logging from '../../config/logging';
import { FULLY_ONBOARDED_LEVEL } from '../../constants/user';
import { initialUserState } from '../../contexts/user';
import { Validate } from '../../modules/auth';
import { updateUserField } from '../../modules/User';

export const LaunchCoinup: React.FC<IOnboardingPage> = ({ userContext }) => {
    const handleSubmit = async () => {
        const userId = userContext.userState.user.userId;

        updateUserField(userId, 'onboardingLevel', FULLY_ONBOARDED_LEVEL).then(
            () => {
                // Refresh user context
                const fire_token = localStorage.getItem('fire_token');
                if (fire_token === null) {
                    userContext.userDispatch({
                        type: 'logout',
                        payload: initialUserState
                    });
                } else {
                    Validate((error, user) => {
                        if (error) {
                            logging.error(error);
                            userContext.userDispatch({
                                type: 'logout',
                                payload: initialUserState
                            });
                        } else if (user) {
                            userContext.userDispatch({
                                type: 'login',
                                payload: { user, fire_token }
                            });
                        }
                    });
                }
            }
        );
    };

    return (
        <Pane
            display="flex"
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
        >
            <Heading size={800}>
                Congratulations, {userContext.userState.user.name.split(' ')[0]}
                !
            </Heading>
            <Text size={400} marginTop={8}>
                You have completed onboarding, you can start using Coinup!
            </Text>
            <Pane marginTop={30} width={400}>
                <Button
                    marginTop={16}
                    appearance="primary"
                    onClick={() => handleSubmit()}
                    width="100%"
                    iconAfter={ArrowTopRightIcon}
                >
                    Launch Coinup
                </Button>
            </Pane>
        </Pane>
    );
};
