import {
    Card as EgCard,
    CardProps,
    Pane,
    PaneProps,
    Icon,
    IconProps
} from 'evergreen-ui';
import styles from './general.module.css';
import { defaultTheme } from '.';

export const Card: React.FC<CardProps> = (props) => {
    const { children, ...other } = props;
    return (
        <EgCard elevation={1} className={styles.card} {...other}>
            {children}
        </EgCard>
    );
};

export interface IconFrameProps extends IconProps {
    type?: 'success' | 'warning' | 'error';
    icon: any;
}

export const IconFrame: React.FC<IconFrameProps & PaneProps> = (props) => {
    const { children, type, icon, ...other } = props;

    let color = {
        bg: defaultTheme.colors.gray200,
        icon: defaultTheme.colors.gray600
    };

    switch (type) {
        case 'success':
            color.bg = defaultTheme.colors.green50;
            color.icon = defaultTheme.colors.green400;
            break;
        case 'warning':
            color.bg = defaultTheme.colors.yellow50;
            color.icon = defaultTheme.colors.yellow400;
            break;
        case 'error':
            color.bg = defaultTheme.colors.red25;
            color.icon = defaultTheme.colors.red500;
            break;
    }

    return (
        <Pane
            className={styles.iconFrame}
            display="flex"
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
            style={{
                backgroundColor: color.bg
            }}
            {...other}
        >
            <Icon icon={icon} size={props.size} color={color.icon} />
        </Pane>
    );
};
