import logging from '../../config/logging';
import { axiosRequest } from '../../config/axios';

const NAMESPACE = 'user';
const ROUTE = '/user/update';

export const updateUserField = async (
    userId: string,
    field: string,
    value: any
) => {
    try {
        const response = await axiosRequest({
            method: 'POST',
            url: `${ROUTE}/${field}`,
            data: { userId, value }
        });
        return await response.data;
    } catch (error) {
        logging.error(error, NAMESPACE);
        throw error;
    }
};
