import {
    Button,
    Heading,
    WarningSignIcon,
    ArrowTopRightIcon,
    TickCircleIcon,
    Text
} from 'evergreen-ui';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { usePlaidLink } from 'react-plaid-link';
import logging from '../../config/logging';
import UserContext, { initialUserState } from '../../contexts/user';
import { Validate } from '../../modules/auth';
import { exchangeToken, generateToken } from '../../modules/plaidSetup';
import { Card, IconFrame } from '../../theme/custom-evergreen';

const PlaidIntegration: React.FC<{}> = (props) => {
    const userContext = useContext(UserContext);
    const [loading, setLoading] = useState(false);

    const VALID_PLAID = userContext.userState.user.plaidIntegrated;

    const [linkToken, setLinkToken] = useState(null);

    function getToken() {
        setLoading(true);
        const userId = userContext.userState.user.userId;
        generateToken(userId).then((data) => {
            setLinkToken(data.link_token);
        });
    }

    useEffect(() => {
        if (loading) {
            setTimeout(() => {
                setLoading(false);
            }, 5000);
        }
    }, [loading]);

    const accounts = userContext.userState.user.plaid?.accounts;
    return (
        <Card
            display="flex"
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
            width={400}
        >
            {!VALID_PLAID ? (
                <>
                    <IconFrame size={20} type="error" icon={WarningSignIcon} />
                    <Heading size={700} marginTop={10}>
                        Let's get you connected
                    </Heading>
                    <Button
                        marginTop={15}
                        disabled={VALID_PLAID}
                        appearance="primary"
                        onClick={() => getToken()}
                        iconAfter={ArrowTopRightIcon}
                        isLoading={loading}
                    >
                        Start
                    </Button>
                </>
            ) : (
                <>
                    <IconFrame size={20} type="success" icon={TickCircleIcon} />
                    <Heading size={700} marginTop={10} marginBottom={4}>
                        Connected!
                    </Heading>
                    {accounts?.map((acc) => {
                        return (
                            <Text size={400} marginTop={4}>
                                {acc.name} | {acc.mask}
                            </Text>
                        );
                    })}
                </>
            )}

            {linkToken != null ? <Link linkToken={linkToken} /> : <></>}
        </Card>
    );
};

const Link: React.FC<{ linkToken: string }> = (props) => {
    const userContext = useContext(UserContext);
    const onSuccess = useCallback(
        (public_token, metadata) => {
            const userId = userContext.userState.user.userId;

            exchangeToken(userId, public_token).then((data) => {
                logging.info('Exchange token success', data);
                // Refresh user context
                const fire_token = localStorage.getItem('fire_token');
                if (fire_token === null) {
                    userContext.userDispatch({
                        type: 'logout',
                        payload: initialUserState
                    });
                } else {
                    Validate((error, user) => {
                        if (error) {
                            logging.error(error);
                            userContext.userDispatch({
                                type: 'logout',
                                payload: initialUserState
                            });
                        } else if (user) {
                            userContext.userDispatch({
                                type: 'login',
                                payload: { user, fire_token }
                            });
                        }
                    });
                }
            });
        },
        [userContext]
    );

    const config: Parameters<typeof usePlaidLink>[0] = {
        token: props.linkToken!,
        onSuccess
    };

    const { open, ready } = usePlaidLink(config);

    useEffect(() => {
        if (ready) {
            open();
        }
    }, [ready, open]);

    return <></>;
};

export default PlaidIntegration;
