import React from 'react';
import AuthButton from '../components/AuthButton/authentication';
import { Pane, Button, Heading } from 'evergreen-ui';
import { useNavigate } from 'react-router-dom';
import { Card } from '../theme/custom-evergreen';
import { Link } from 'react-router-dom';

const SignUp: React.FunctionComponent<{}> = () => {
    const navigate = useNavigate();
    return (
        <Pane display="flex" alignItems="center" justifyContent="center" className="fullHeightWithNav" marginTop={-40}>
            <Card>
                <Heading size={600}>Get started with Coinup</Heading>
                <Heading size={200} paddingTop={0}>
                    A few clicks away from passive income.
                </Heading>
                <Pane marginTop={30}>
                    <AuthButton type={'create'} />
                    <Button onClick={() => navigate('/login', { replace: true })} appearance="minimal">
                        Already have an account? Log in
                    </Button>
                </Pane>
                <Heading size={200} marginTop={20}>
                    By signing up, you're agreeing to our <Link to="#">Terms &amp; Conditions</Link> and <Link to="#">Privacy Policy</Link>
                </Heading>
            </Card>
        </Pane>
    );
};

export default SignUp;
