import { Heading, Pane, Spinner, Table, Text } from 'evergreen-ui';
import React, { useContext, useEffect, useState } from 'react';
import UserContext from '../contexts/user';
import { getLatestTransactionsByUserId } from '../modules/Plaid';
import { Card } from '../theme/custom-evergreen';
import { defaultTheme } from '../theme';
import { WeeklyBalancePlot } from '../components/Plots';
import { getWeeklyBalances } from '../modules/Balance';
import moment from 'moment';

const Dashboard: React.FC<{}> = (props) => {
    const userContext = useContext(UserContext);
    const [transactions, setTransactions] = useState<any[]>([]);
    const [balances, setBalances] = useState<any>();

    useEffect(() => {
        const userId = userContext.userState.user.userId;
        getLatestTransactionsByUserId(userId, 30).then((transactions) =>
            setTransactions(transactions)
        );

        getWeeklyBalances(userId).then((res) => {
            const balances = res.balances;
            let currentBalance = 0;
            let totalInvested = 0;
            if (balances.length > 0) {
                currentBalance = balances[balances.length - 1].balance;
                totalInvested = balances.reduce(
                    (partialSum: number, balance: any) =>
                        partialSum + balance.balance,
                    0
                );
            }

            const lastUpdated = moment(
                balances[balances.length - 1].lastUpdated
            ).toDate();
            const howLongAgo = getHowLongAgo(lastUpdated);

            setBalances({ currentBalance, totalInvested, howLongAgo });
        });
    }, [userContext.userState.user.userId]);

    const formatCurrency = (amount: number) => {
        if (!amount) {
            return '$0.00';
        }
        return `$${amount.toFixed(2)}`;
    };

    const getHowLongAgo = (date: Date) => {
        const now = new Date();
        const diff = now.getTime() - date.getTime();
        const hours = Math.floor(diff / (1000 * 60 * 60));
        const days = Math.floor(diff / (1000 * 60 * 60 * 24));
        const minutes = Math.floor(diff / (1000 * 60));
        if (days < 1) {
            if (hours < 1) {
                return `${minutes} minutes ago`;
            } else {
                return `${hours} hours ago`;
            }
        } else {
            return `${days} days ago`;
        }
    };

    return (
        <Pane>
            <Pane flex={1} display="flex" flexDirection="row">
                <Card>
                    <Text size={500}>Current Balance</Text>
                    <Heading size={900}>
                        {balances ? (
                            formatCurrency(balances?.currentBalance)
                        ) : (
                            <Spinner />
                        )}
                    </Heading>
                    <Text size={400} color={defaultTheme.colors.gray600}>
                        Last updated {balances?.howLongAgo}
                    </Text>
                </Card>
                <Card marginLeft={30}>
                    <Text size={500}>Total Invested</Text>
                    <Heading size={900}>
                        {balances ? (
                            formatCurrency(balances?.totalInvested)
                        ) : (
                            <Spinner />
                        )}
                    </Heading>
                    <Text size={400} color={defaultTheme.colors.gray600}>
                        Last updated {balances?.howLongAgo}
                    </Text>
                </Card>
            </Pane>
            <Card marginTop={30}>
                <Heading size={700}>Overview</Heading>
                <WeeklyBalancePlot />
            </Card>
            <Card marginTop={30}>
                <Heading size={700}>Latest Transactions</Heading>
                <Pane>
                    <Table marginTop={16} width={1024} flex={1}>
                        <Table.Head>
                            <Table.TextHeaderCell>Name</Table.TextHeaderCell>
                            <Table.TextHeaderCell>
                                Category
                            </Table.TextHeaderCell>
                            <Table.TextHeaderCell>Amount</Table.TextHeaderCell>
                            <Table.TextHeaderCell>
                                Round Up
                            </Table.TextHeaderCell>
                            <Table.TextHeaderCell>Date</Table.TextHeaderCell>
                        </Table.Head>
                        <Table.VirtualBody height={300}>
                            {transactions.map((t) => (
                                <Table.Row key={t.transactionId}>
                                    <Table.TextCell>
                                        {t.merchantName.length
                                            ? t.merchantName
                                            : t.name}
                                    </Table.TextCell>
                                    <Table.TextCell>
                                        {t.category.join(', ')}
                                    </Table.TextCell>
                                    <Table.TextCell>
                                        {formatCurrency(t.amount)}
                                    </Table.TextCell>
                                    <Table.TextCell>
                                        {formatCurrency(
                                            Math.ceil(t.amount) - t.amount
                                        )}
                                    </Table.TextCell>
                                    <Table.TextCell>{t.date}</Table.TextCell>
                                </Table.Row>
                            ))}
                        </Table.VirtualBody>
                    </Table>
                </Pane>
            </Card>
        </Pane>
    );
};

export default Dashboard;
