import { Button, Heading, Pane, Text, ArrowRightIcon } from 'evergreen-ui';
import React from 'react';
import { IOnboardingPage } from '.';
import logging from '../../config/logging';
import { initialUserState } from '../../contexts/user';
import { Validate } from '../../modules/auth';
import { updateUserField } from '../../modules/User';
import PlaidIntegration from '../PlaidIntegration';

export const BankAccounts: React.FC<IOnboardingPage> = ({ userContext }) => {
    const VALID_PLAID = userContext.userState.user.plaidIntegrated;

    const handleSubmit = async () => {
        const userId = userContext.userState.user.userId;

        updateUserField(userId, 'onboardingLevel', 3).then(() => {
            // Refresh user context
            const fire_token = localStorage.getItem('fire_token');
            if (fire_token === null) {
                userContext.userDispatch({
                    type: 'logout',
                    payload: initialUserState
                });
            } else {
                Validate((error, user) => {
                    if (error) {
                        logging.error(error);
                        userContext.userDispatch({
                            type: 'logout',
                            payload: initialUserState
                        });
                    } else if (user) {
                        userContext.userDispatch({
                            type: 'login',
                            payload: { user, fire_token }
                        });
                    }
                });
            }
        });
    };

    return (
        <Pane
            display="flex"
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
        >
            <Heading size={800}>It's time to connect your bank</Heading>
            <Text size={400} marginTop={8}>
                You can always edit this integration later.
            </Text>
            <Pane
                marginTop={60}
                width={400}
                display="flex"
                justifyContent="center"
                alignItems="center"
                flexDirection="column"
            >
                <PlaidIntegration />
                <Button
                    disabled={!VALID_PLAID}
                    marginTop={55}
                    appearance="primary"
                    onClick={() => handleSubmit()}
                    width="100%"
                    iconAfter={ArrowRightIcon}
                >
                    Next Step
                </Button>
            </Pane>
        </Pane>
    );
};
