import { Heading, Paragraph, StatusIndicator, CardProps } from 'evergreen-ui';
import React from 'react';
import { Card } from '../../theme/custom-evergreen';

export interface IAccountDetailsProps extends CardProps {
    status: string;
    name: string;
    accountnumber?: string;
    currency?: string;
    username?: string;
    email?: string;
}

const AccountDetails: React.FC<IAccountDetailsProps> = (props) => {
    return (
        <Card {...props} marginRight={8}>
            <StatusIndicator
                color={props.status === 'enabled' ? 'success' : 'danger'}
            >
                {props.status === 'enabled' ? 'Enabled' : 'Disabled'}
            </StatusIndicator>
            <Heading size={700}>{props.name}</Heading>
            {props.currency && (
                <>
                    <Heading size={400} marginTop={30}>
                        Account Number
                    </Heading>
                    <Paragraph size={400}>{props.accountnumber}</Paragraph>
                </>
            )}
            {props.currency && (
                <>
                    <Heading size={400} marginTop={10}>
                        Currency
                    </Heading>
                    <Paragraph size={400}>{props.currency}</Paragraph>
                </>
            )}{' '}
            {props.username && (
                <>
                    <Heading size={400} marginTop={10}>
                        Name
                    </Heading>
                    <Paragraph size={400}>{props.username}</Paragraph>
                </>
            )}
            {props.email && (
                <>
                    <Heading size={400} marginTop={10}>
                        Email
                    </Heading>
                    <Paragraph size={400}>{props.email}</Paragraph>
                </>
            )}
        </Card>
    );
};

export default AccountDetails;
