import {
    Button,
    Heading,
    Pane,
    RadioGroup,
    Text,
    ArrowRightIcon,
    TextInput
} from 'evergreen-ui';
import React, { useState } from 'react';
import { IOnboardingPage } from '.';
import logging from '../../config/logging';
import { initialUserState } from '../../contexts/user';
import { Validate } from '../../modules/auth';
import { updateUserField } from '../../modules/User';

export const InvestmentPreferences: React.FC<IOnboardingPage> = ({
    userContext
}) => {
    const [hardLimit, setHardLimit] = useState<string>('20.00');
    const [roundUpToNearest, setRoundUpToNearest] = useState<string>('1');

    const [roundUpToNearestOptions] = useState([
        { label: '$1', value: '1' },
        { label: '$2', value: '2' },
        { label: '$5', value: '5' },
        { label: '$10', value: '10' }
    ]);

    const handleSubmit = async () => {
        const userId = userContext.userState.user.userId;
        const maxInvestmentPerWeek = parseInt(hardLimit);
        const roundUpToNearestNum = parseInt(roundUpToNearest);

        await updateUserField(userId, 'investingPreferences', {
            maxInvestmentPerWeek,
            roundUpToNearestNum
        });

        updateUserField(userId, 'onboardingLevel', 2).then(() => {
            // Refresh user context
            const fire_token = localStorage.getItem('fire_token');
            if (fire_token === null) {
                userContext.userDispatch({
                    type: 'logout',
                    payload: initialUserState
                });
            } else {
                Validate((error, user) => {
                    if (error) {
                        logging.error(error);
                        userContext.userDispatch({
                            type: 'logout',
                            payload: initialUserState
                        });
                    } else if (user) {
                        userContext.userDispatch({
                            type: 'login',
                            payload: { user, fire_token }
                        });
                    }
                });
            }
        });
    };

    return (
        <Pane
            display="flex"
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
        >
            <Heading size={800}>
                Tell us about your investment preferences
            </Heading>
            <Text size={400} marginTop={8}>
                You can always change these settings later.
            </Text>

            <Pane marginTop={60} width={400}>
                <Text fontWeight={500}>Round up to the nearest...</Text>
                <RadioGroup
                    options={roundUpToNearestOptions}
                    value={roundUpToNearest}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        setRoundUpToNearest(e.target.value)
                    }
                />
                <Text fontWeight={500} marginTop={8}>
                    What's the maximum you'd like to invest per week?
                </Text>
                <TextInput
                    isInvalid={!(parseInt(hardLimit) > 0)}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        setHardLimit(e.target.value)
                    }
                    value={hardLimit}
                    width="100%"
                />
                <Button
                    disabled={!(parseInt(hardLimit) > 0)}
                    marginTop={10}
                    appearance="primary"
                    onClick={() => handleSubmit()}
                    width="100%"
                    iconAfter={ArrowRightIcon}
                >
                    Next Step
                </Button>
            </Pane>
        </Pane>
    );
};
