import axios, { Method } from 'axios';
import config from './config';

axios.defaults.baseURL = config.server.url;

export interface IAxiosRequestParams {
    method: Method;
    url: string;
    data?: any;
    headers?: any;
}

export const axiosRequest = async ({
    method,
    url,
    data,
    headers
}: IAxiosRequestParams) => {
    const fire_token = localStorage.getItem('fire_token');
    if (fire_token) {
        headers = {
            ...headers,
            Authorization: `Bearer ${fire_token}`
        };
    }
    const request = {
        method,
        url,
        data,
        headers
    };

    const response = await axios(request);

    return response;
};
