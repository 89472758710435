import { defaultTheme as evergreenDefaultTheme } from 'evergreen-ui';
import { merge } from 'lodash';

export const defaultTheme: any = merge({}, evergreenDefaultTheme, {
    fontFamilies: {
        display:
            '"Euclid Circular B", "SF UI Display", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
        ui: '"Euclid Circular B", "SF UI Text", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
        mono: '"SF Mono", "Monaco", "Inconsolata", "Fira Mono", "Droid Sans Mono", "Source Code Pro", monospace'
    },
    letterSpacings: {
        tightest: '-0.5px',
        tighter: '-0.5px',
        tight: '-0.5px',
        normal: '-0.5px',
        wide: '0.6px'
    },
    colors: {
        green50: '#F5FBF8',
        green400: '#52BD95',
        yellow50: '#FFFAF1',
        yellow400: '#FFB020',
        red50: '#FDF4F4',
        red400: '#D14343',
        primary: '#3366FF'
    }
});
