import { axiosRequest } from '../../config/axios';
import logging from '../../config/logging';

const NAMESPACE = 'user';
const ROUTE = '/balance';

export const getWeeklyBalances = async (userId: string) => {
    try {
        const response = await axiosRequest({
            method: 'GET',
            url: `${ROUTE}/${userId}`
        });
        return await response.data;
    } catch (error) {
        logging.error(error, NAMESPACE);
        throw error;
    }
};
