const config = {
    firebase: {
        apiKey: 'AIzaSyCH5y9HlqLJ-A8POEaSY-E2Lv_SjW5bWp0',
        authDomain: 'coinup-app.firebaseapp.com',
        projectId: 'coinup-app',
        storageBucket: 'coinup-app.appspot.com',
        messagingSenderId: '400829328099',
        appId: '1:400829328099:web:c8ab47db57ac35b903f3d6'
    },
    server: {
        url: 'https://sandbox-api.coinupapp.ca'
    },
    coinbase: {
        client_id:
            '96250ad4e2d5d5e38467163c02ff33c08f119f6db5d3173c4e89c02207ab8e71',
        required_scopes: [
            'wallet:accounts:read',
            'wallet:accounts:update',
            'wallet:accounts:create',
            'wallet:accounts:delete',
            'wallet:addresses:read',
            'wallet:addresses:create',
            'wallet:buys:read',
            'wallet:buys:create',
            'wallet:deposits:read',
            'wallet:deposits:create',
            'wallet:payment-methods:read',
            'wallet:payment-methods:delete',
            'wallet:payment-methods:limits',
            'wallet:sells:read',
            'wallet:sells:create',
            'wallet:transactions:read',
            'wallet:transactions:transfer',
            'wallet:user:email',
            'wallet:user:read',
            'wallet:withdrawals:read',
            'wallet:withdrawals:create'
        ]
    }
};

export default config;
