import firebase from 'firebase';
import { auth } from '../config/firebase';
import IUser from '../interfaces/user';
import logging from '../config/logging';
import { axiosRequest } from '../config/axios';

const NAMESPACE = 'auth';

export const SignInWithSocialMedia = (provider: firebase.auth.AuthProvider) =>
    new Promise<firebase.auth.UserCredential>((resolve, reject) => {
        auth.signInWithPopup(provider)
            .then((result) => resolve(result))
            .catch((error) => reject(error));
    });

export const Authenticate = async (
    newUser: any,
    fire_token: string,
    type: string,
    callback: (error: string | null, user: IUser | null) => void
) => {
    try {
        let response = await axiosRequest({
            method: 'POST',
            url: `/user/${type}`,
            data: { ...newUser },
            headers: { Authorization: `Bearer ${fire_token}` }
        });

        if (
            response.status === 200 ||
            response.status === 201 ||
            response.status === 304
        ) {
            logging.info('Successfully authenticated.', NAMESPACE);
            callback(null, response.data.user);
        } else {
            logging.warn('Unable to authenticate.', NAMESPACE);
            callback('Unable to authenticate.', null);
        }
    } catch (error) {
        logging.error(error, NAMESPACE);
        callback('Unable to authenticate.', null);
    }
};

export const Validate = async (
    callback: (error: string | null, user: IUser | null) => void
) => {
    try {
        let response = await axiosRequest({
            method: 'GET',
            url: `/user/validate`
        });

        if (response.status === 200 || response.status === 304) {
            logging.info('Successfully validated.', NAMESPACE);
            callback(null, response.data.user);
        } else {
            logging.warn(response, NAMESPACE);
            callback('Unable to validate.', null);
        }
    } catch (error) {
        logging.error(error, NAMESPACE);
        callback('Unable to validate.', null);
    }
};
