import React, { useContext } from 'react';
import { Pane, Heading, Icon, Text } from 'evergreen-ui';
import styles from './styles.module.css';
import { Avatar } from 'evergreen-ui';
import { defaultTheme } from '../../theme';
import { NavLink, useMatch, useResolvedPath } from 'react-router-dom';
import UserContext from '../../contexts/user';
import routes from '../../config/routes';
import IRoute from '../../interfaces/route';

const Sidebar: React.FC = () => {
    const userContext = useContext(UserContext);
    return (
        <div className={styles.sidebar}>
            <Pane display="flex" className={styles.avatarGroup} alignItems="center">
                <Avatar name={userContext.userState.user.name} size={40} color="neutral" marginRight={20} />
                <Heading size={500} color={defaultTheme.colors.gray400} className="col">
                    {userContext.userState.user.name}
                </Heading>
            </Pane>
            {/* Map sidebar items */}
            {routes.map((route, idx) => {
                return <SidebarItem key={idx} {...route} />;
            })}
            <Text className={styles.sidebarBrand} color={defaultTheme.colors.gray400}>
                © 2022 Coinup | Privacy | Terms
            </Text>
        </div>
    );
};

const SidebarItem: React.FC<IRoute> = (props) => {
    const { icon, name, path, requiresSidebar, requiresIntegrations } = props;
    const userContext = useContext(UserContext);
    let resolved = useResolvedPath(path);
    let match = useMatch({ path: resolved.pathname, end: true });

    const ONBOARDING_COMPLETE = userContext.userState.user.onboardingLevel === 5;

    if (!requiresSidebar) return null;
    if (requiresIntegrations && !ONBOARDING_COMPLETE) return null;

    const handleActive = (props: { isActive: boolean }) => {
        const classNames = [styles.sidebarItem, 'position-relative', props.isActive ? styles.sidebarItemActive : ''].join(' ');
        return classNames;
    };

    return (
        <NavLink to={path} end className={handleActive}>
            {match ? <div className={[styles.sidebarItemSide, 'position-absolute'].join(' ')}></div> : null}
            <div className={[styles.sidebarItemContent, 'position-relative'].join(' ')}>
                <div className="text-white text-decoration-none">
                    <Pane display="flex">
                        <Pane display="inline-flex" width={40} justifyContent="center" marginRight={20}>
                            <Icon icon={icon} size={20} />
                        </Pane>
                        <Heading size={500} color={defaultTheme.colors.gray400}>
                            {name}
                        </Heading>
                    </Pane>
                </div>
            </div>
        </NavLink>
    );
};

export default Sidebar;
