import { Pane, Heading, Paragraph } from 'evergreen-ui';
import _ from 'lodash';
import React, { useContext } from 'react';
import AccountDetails from '../components/AccountDetails';
import UserContext from '../contexts/user';

const Accounts: React.FC<{}> = (props) => {
    const userContext = useContext(UserContext);
    const accounts = userContext.userState.user.plaid?.accounts;
    const chunkedAccounts = accounts ? _.chunk(accounts, 5) : [];
    const coinbaseUserInfo =
        userContext.userState.user.coinbase?.userInfo?.data;
    return (
        <Pane>
            <Heading size={800}>Bank Accounts</Heading>
            <Paragraph size={500}>
                View and manage your connected accounts
            </Paragraph>
            <Pane>
                {chunkedAccounts?.map((accounts) => {
                    return (
                        <Pane display="flex">
                            {accounts.map((acc) => {
                                return (
                                    <AccountDetails
                                        status="enabled"
                                        name={acc.name}
                                        accountnumber={acc.mask}
                                        currency={
                                            acc.balances.iso_currency_code
                                        }
                                        marginTop={30}
                                    />
                                );
                            })}
                        </Pane>
                    );
                })}
            </Pane>
            <Heading marginTop={32} size={800}>
                Coinbase Account
            </Heading>
            <Paragraph size={500}>
                View and manage your crypto accounts
            </Paragraph>
            <Pane display="flex">
                <AccountDetails
                    status="enabled"
                    name="Coinbase"
                    username={coinbaseUserInfo.name}
                    email={coinbaseUserInfo.email}
                    marginTop={30}
                />
            </Pane>
        </Pane>
    );
};

export default Accounts;
