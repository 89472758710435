import logging from '../../config/logging';
import { axiosRequest } from '../../config/axios';

const NAMESPACE = 'plaid';
const ROUTE = '/plaid/transactions';

export const getLatestTransactionsByUserId = async (
    userId: string,
    limit: number
) => {
    try {
        const response = await axiosRequest({
            method: 'POST',
            url: `${ROUTE}/user/${userId}`,
            data: { limit }
        });
        return await response.data;
    } catch (error) {
        logging.error(error, NAMESPACE);
        throw error;
    }
};
