import logging from '../config/logging';
import { axiosRequest } from '../config/axios';

const NAMESPACE = 'plaid';
const ROUTE = '/plaid/setup';

export const generateToken = async (userId: string) => {
    try {
        const response = await axiosRequest({
            method: 'POST',
            url: `${ROUTE}/create_link_token`,
            data: { userId }
        });
        return await response.data;
    } catch (error) {
        logging.error(error, NAMESPACE);
        throw error;
    }
};

export const exchangeToken = async (userId: string, publicToken: string) => {
    try {
        const response = await axiosRequest({
            method: 'POST',
            url: `${ROUTE}/exchange_public_token`,
            data: { publicToken, userId }
        });
        return await response.data;
    } catch (error) {
        logging.error(error, NAMESPACE);
        throw error;
    }
};
