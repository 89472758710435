import React from 'react';
import { Pane, Heading } from 'evergreen-ui';
import styles from './styles.module.css';
import { defaultTheme } from '../../theme';

export interface IStepsProps {
    total: number;
    current: number;
}

const Steps: React.FC<IStepsProps> = (props) => {
    const { total, current } = props;
    const steps = [];
    for (let i = 1; i <= total; i++) {
        steps.push(
            <React.Fragment key={i}>
                <Pane
                    className={[
                        styles.step,
                        i <= current ? styles.complete : ''
                    ].join(' ')}
                >
                    <Heading
                        color={
                            i <= current ? 'white' : defaultTheme.colors.gray600
                        }
                        size={500}
                    >
                        {i}
                    </Heading>
                </Pane>
                {i < total ? (
                    <>
                        <Pane
                            className={
                                i <= current ? styles.link1 : styles.link2
                            }
                        ></Pane>
                        <Pane
                            className={
                                i < current ? styles.link1 : styles.link2
                            }
                        ></Pane>
                    </>
                ) : null}
            </React.Fragment>
        );
    }
    return (
        <Pane display="flex" alignItems="center">
            {steps}
        </Pane>
    );
};

export default Steps;
