import { Pane, Spinner } from 'evergreen-ui';
import React, { useEffect, useReducer, useState } from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import { ValidRoute } from './components/AuthRoute';
import logging from './config/logging';
import routes from './config/routes';
import {
    initialUserState,
    UserContextProvider,
    userReducer
} from './contexts/user';
import { Validate } from './modules/auth';
import { ThemeProvider } from 'evergreen-ui';
import { defaultTheme } from './theme';
import DashboardNavbar from './components/DashboardNavbar';
import Sidebar from './components/Sidebar';

export interface IAppProps {}

const App: React.FunctionComponent<IAppProps> = (props) => {
    const [userState, userDispatch] = useReducer(userReducer, initialUserState);
    const [loading, setLoading] = useState<boolean>(true);

    useEffect(() => {
        CheckLocalStorageForCredentials();
    }, []);

    const CheckLocalStorageForCredentials = () => {
        // Checking credentials
        const fire_token = localStorage.getItem('fire_token');

        if (fire_token === null) {
            userDispatch({ type: 'logout', payload: initialUserState });
            setLoading(false);
        } else {
            Validate((error, user) => {
                if (error) {
                    logging.error(error);
                    userDispatch({ type: 'logout', payload: initialUserState });
                } else if (user) {
                    userDispatch({
                        type: 'login',
                        payload: { user, fire_token }
                    });
                }
                setLoading(false);
            });
        }
    };

    const userContextValues = {
        userState,
        userDispatch
    };

    if (loading) {
        return <Spinner />;
    }

    return (
        <UserContextProvider value={userContextValues}>
            <ThemeProvider value={defaultTheme}>
                <DashboardNavbar />
                <Routes>
                    {routes.map((route, idx) => {
                        return (
                            <Route
                                key={idx}
                                path={route.path}
                                element={
                                    <ValidRoute route={route}>
                                        {route.requiresSidebar ? (
                                            <Pane
                                                display="flex"
                                                flexDirection="row"
                                            >
                                                <Sidebar />
                                                <Pane
                                                    marginLeft={50}
                                                    marginTop={50}
                                                >
                                                    <route.component
                                                        {...route.props}
                                                    />{' '}
                                                </Pane>
                                            </Pane>
                                        ) : (
                                            <route.component {...route.props} />
                                        )}
                                    </ValidRoute>
                                }
                            />
                        );
                    })}
                    <Route
                        path="/"
                        element={<Navigate to="/login" replace />}
                    />
                </Routes>
            </ThemeProvider>
        </UserContextProvider>
    );
};

export default App;
