import IRoute from '../interfaces/route';
import Dashboard from '../pages/dashboard';
import Login from '../pages/login';
import SignUp from '../pages/signup';
import Invest from '../pages/invest';
import Onboarding from '../pages/onboarding';
import Accounts from '../pages/accounts';
import { DashboardIcon, LayersIcon, TrendingUpIcon } from 'evergreen-ui';

const unauthRoutes: IRoute[] = [
    {
        name: 'Login',
        path: '/login',
        component: Login,
        requiresAuth: false,
        requiresIntegrations: false,
        requiresSidebar: false
    },
    {
        name: 'Sign Up',
        path: '/signup',
        component: SignUp,
        requiresAuth: false,
        requiresIntegrations: false,
        requiresSidebar: false
    }
];

const authRoutes: IRoute[] = [
    {
        name: 'Onboarding',
        path: '/onboarding',
        component: Onboarding,
        requiresAuth: true,
        requiresIntegrations: false,
        requiresSidebar: false
    },
    {
        name: 'Dashboard',
        path: '/dashboard',
        component: Dashboard,
        requiresAuth: true,
        requiresIntegrations: true,
        requiresSidebar: true,
        icon: DashboardIcon
    },
    {
        name: 'Invest',
        path: '/invest',
        component: Invest,
        requiresAuth: true,
        requiresIntegrations: true,
        requiresSidebar: true,
        icon: TrendingUpIcon
    },
    {
        name: 'Accounts',
        path: '/accounts',
        component: Accounts,
        requiresAuth: true,
        requiresIntegrations: true,
        requiresSidebar: true,
        icon: LayersIcon
    }
];

const routes: IRoute[] = [...unauthRoutes, ...authRoutes];

export default routes;
