import {
    Button,
    Heading,
    Pane,
    TextInputField,
    Text,
    ArrowRightIcon
} from 'evergreen-ui';
import React, { useState } from 'react';
import { IOnboardingPage } from '.';
import logging from '../../config/logging';
import { initialUserState } from '../../contexts/user';
import { Validate } from '../../modules/auth';
import { updateUserField } from '../../modules/User';

export const PersonalDetails: React.FC<IOnboardingPage> = ({ userContext }) => {
    const [name, setName] = useState(userContext.userState.user.name);
    const [email, setEmail] = useState(userContext.userState.user.email);

    const handleSubmit = async () => {
        const userId = userContext.userState.user.userId;
        if (name !== userContext.userState.user.name) {
            await updateUserField(userId, 'name', name);
        }
        if (email !== userContext.userState.user.email) {
            await updateUserField(userId, 'email', email);
        }
        updateUserField(userId, 'onboardingLevel', 1).then(() => {
            // Refresh user context
            const fire_token = localStorage.getItem('fire_token');
            if (fire_token === null) {
                userContext.userDispatch({
                    type: 'logout',
                    payload: initialUserState
                });
            } else {
                Validate((error, user) => {
                    if (error) {
                        logging.error(error);
                        userContext.userDispatch({
                            type: 'logout',
                            payload: initialUserState
                        });
                    } else if (user) {
                        userContext.userDispatch({
                            type: 'login',
                            payload: { user, fire_token }
                        });
                    }
                });
            }
        });
    };

    return (
        <Pane
            display="flex"
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
        >
            <Heading size={800}>Welcome! First things first...</Heading>
            <Text size={400} marginTop={8}>
                You can always change them later.
            </Text>

            <Pane marginTop={60} width={400}>
                <TextInputField
                    label="Full Name"
                    isInvalid={!name?.length}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        setName(e.target.value)
                    }
                    value={name}
                />
                <TextInputField
                    marginTop={8}
                    label="Email"
                    isInvalid={!email?.length}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        setEmail(e.target.value)
                    }
                    value={email}
                />
                <Button
                    disabled={!name?.length || !email?.length}
                    marginTop={10}
                    appearance="primary"
                    onClick={() => handleSubmit()}
                    width="100%"
                    iconAfter={ArrowRightIcon}
                >
                    Next Step
                </Button>
            </Pane>
        </Pane>
    );
};
