import { Pane, Heading } from 'evergreen-ui';
import React, { useContext } from 'react';
import { Navigate } from 'react-router';
import {
    PersonalDetails,
    InvestmentPreferences,
    BankAccounts,
    CryptoAccounts,
    LaunchCoinup
} from '../components/Onboarding';
import { FULLY_ONBOARDED_LEVEL } from '../constants/user';
import UserContext from '../contexts/user';
import Steps from '../components/Steps';
import { defaultTheme } from '../theme';

const Onboarding: React.FC<{}> = (props) => {
    const userContext = useContext(UserContext);
    const onboardingLevel = userContext.userState.user.onboardingLevel;
    const ONBOARDING_COMPLETE = onboardingLevel === FULLY_ONBOARDED_LEVEL;
    const pages = [
        <PersonalDetails userContext={userContext} />,
        <InvestmentPreferences userContext={userContext} />,
        <BankAccounts userContext={userContext} />,
        <CryptoAccounts userContext={userContext} />,
        <LaunchCoinup userContext={userContext} />
    ];

    if (ONBOARDING_COMPLETE) return <Navigate to="/dashboard" />;

    return (
        <Pane
            display="flex"
            alignItems="center"
            justifyContent="center"
            flexDirection="column"
            className="topSpacing"
        >
            <Steps
                current={onboardingLevel + 1}
                total={FULLY_ONBOARDED_LEVEL}
            />
            <Heading
                className="brandText"
                color={defaultTheme.colors.gray900}
                marginTop={50}
            >
                Coinup
            </Heading>
            <Pane marginTop={50}>{pages[onboardingLevel]}</Pane>
        </Pane>
    );
};

export default Onboarding;
