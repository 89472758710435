import React, { useContext } from 'react';
import { Button, Pane, Heading } from 'evergreen-ui';
import styles from './styles.module.css';
import UserContext, { initialUserState } from '../../contexts/user';
import { Link } from 'react-router-dom';

const DashboardNavbar: React.FC = () => {
    const userContext = useContext(UserContext);
    const IS_LOGGED_IN = userContext.userState.user._id !== '';
    return (
        <Pane
            className={styles.mainNav}
            alignItems="center"
            display="flex"
            justifyContent="space-between"
        >
            <Heading className="brandText" color="white">
                Coinup
            </Heading>
            {IS_LOGGED_IN && (
                <Link to={'/login'}>
                    <Button
                        className={styles.logoutButton}
                        onClick={() =>
                            userContext.userDispatch({
                                type: 'logout',
                                payload: initialUserState
                            })
                        }
                    >
                        Sign out
                    </Button>
                </Link>
            )}
        </Pane>
    );
};

export default DashboardNavbar;
