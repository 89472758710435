import { Pane, Spinner, Tab, Tablist } from 'evergreen-ui';
import React, { useContext, useEffect, useState } from 'react';
import Chart from 'react-apexcharts';
import moment from 'moment';
import { getWeeklyBalances } from '../../modules/Balance';
import UserContext from '../../contexts/user';
import { defaultTheme } from '../../theme';

export interface IBalance {
    userId: string;
    startDate: Date;
    endDate: Date;
    balance: number;
    currency: string;
    lastUpdated: Date;
}

export const WeeklyBalancePlot: React.FunctionComponent<{}> = (props) => {
    const userContext = useContext(UserContext);

    const userId = userContext.userState.user.userId;
    const now = moment().toDate();

    const [timelineIdx, setTimelineIdx] = useState<number>(3);
    const [balances, setBalances] = useState<IBalance[]>();
    const [seriesData, setSeriesData] = useState<(number | IBalance)[][]>();
    const [yMax, setYMax] = useState<number>(0);
    const [timelineOptions] = useState([
        'Month',
        '3 Months',
        'Year',
        'All Time'
    ]);

    useEffect(() => {
        getWeeklyBalances(userId).then((res) => setBalances(res.balances));
    }, [userId]);

    useEffect(() => {
        if (balances?.length) {
            let sofar = 0;
            const result = balances.map((balance) => {
                const x = moment(balance.endDate).toDate().getTime();
                const y = sofar + balance.balance;
                sofar += balance.balance;
                const info = balance;
                return [x, y, info];
            });
            setYMax(sofar);
            setSeriesData(result);
        }
    }, [balances]);

    useEffect(() => {
        if (seriesData) {
            const selected = timelineOptions[timelineIdx];
            if (selected === 'All Time') {
                ApexCharts.exec(
                    'area-datetime',
                    'zoomX',
                    moment((seriesData[0][2] as IBalance)['startDate'])
                        .toDate()
                        .getTime(),
                    now.getTime()
                );
            } else if (selected === 'Year') {
                ApexCharts.exec(
                    'area-datetime',
                    'zoomX',
                    moment().subtract(1, 'year').toDate().getTime(),
                    now.getTime()
                );
            } else if (selected === '3 Months') {
                ApexCharts.exec(
                    'area-datetime',
                    'zoomX',
                    moment().subtract(3, 'month').toDate().getTime(),
                    now.getTime()
                );
            } else if (selected === 'Month') {
                ApexCharts.exec(
                    'area-datetime',
                    'zoomX',
                    moment().subtract(1, 'month').toDate().getTime(),
                    now.getTime()
                );
            }
        }
    }, [timelineIdx, now, seriesData, timelineOptions]);

    const series = [{ data: seriesData }];
    const options = {
        chart: {
            id: 'area-datetime',
            type: 'area' as 'area',
            zoom: {
                autoScaleYaxis: true
            },
            toolbar: {
                show: false
            }
        },
        dataLabels: {
            enabled: false
        },
        markers: {
            size: 0,
            style: 'hollow'
        },
        xaxis: {
            type: 'datetime' as 'datetime',
            min: seriesData
                ? moment((seriesData[0][2] as IBalance)['startDate'])
                      .toDate()
                      .getTime()
                : moment().subtract(1, 'year').toDate().getTime(),
            tickAmount: 6,
            tooltip: {
                enabled: false
            }
        },
        yaxis: {
            min: 0,
            max: Math.ceil(yMax) > 1 ? Math.ceil(yMax) : 1
        },
        tooltip: {
            x: {
                format: 'dd MMM yyyy'
            },
            custom: function ({ series, seriesIndex, dataPointIndex, w }: any) {
                return (
                    '<div class="arrow_box">' +
                    '<span>' +
                    series[seriesIndex][dataPointIndex] +
                    '</span>' +
                    '</div>'
                );
            }
        },
        colors: [defaultTheme.colors.primary],
        fill: {
            type: 'gradient',
            gradient: {
                shadeIntensity: 1,
                opacityFrom: 0.7,
                opacityTo: 0.9,
                stops: [0, 100]
            }
        }
    };

    return (
        <>
            <Tablist display="flex" justifyContent="flex-end">
                {timelineOptions.map((tab, idx) => (
                    <Tab
                        disabled={!seriesData}
                        key={tab}
                        id={tab}
                        onSelect={() => setTimelineIdx(idx)}
                        isSelected={idx === timelineIdx}
                    >
                        {tab}
                    </Tab>
                ))}
            </Tablist>
            {seriesData ? (
                <Chart
                    options={options}
                    series={series}
                    type="area"
                    height={335}
                />
            ) : (
                <Pane
                    height={350}
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                >
                    <Spinner />
                </Pane>
            )}
        </>
    );
};
