import {
    Button,
    Heading,
    Text,
    WarningSignIcon,
    ArrowTopRightIcon,
    TickCircleIcon
} from 'evergreen-ui';
import React, { useContext, useState, useEffect } from 'react';
import UserContext, { initialUserState } from '../../contexts/user';
import config from '../../config/config';
import { useSearchParams, useNavigate, useLocation } from 'react-router-dom';
import { exchangeToken } from '../../modules/coinbaseSetup';
import logging from '../../config/logging';
import { Validate } from '../../modules/auth';
import { Card, IconFrame } from '../../theme/custom-evergreen';

const CoinbaseIntegration: React.FunctionComponent<{}> = (props) => {
    const userContext = useContext(UserContext);
    const [searchParams] = useSearchParams();
    const [loading, setLoading] = useState(false);

    const publicToken = searchParams.get('code');
    const navigate = useNavigate();
    const location = useLocation();
    searchParams.delete('code');
    // remove the code from the url
    if (location.search !== searchParams.toString()) {
        navigate(location.pathname + '?' + searchParams.toString());
    }

    const VALID_COINBASE = userContext.userState.user.coinbaseIntegrated;
    const requiredScopes = config.coinbase.required_scopes.join(',');
    const integrationUri = `https://www.coinbase.com/oauth/authorize?client_id=${config.coinbase.client_id}&redirect_uri=${window.location.href}&response_type=code&scope=${requiredScopes}`;

    const redirectToIntegratePage = () => {
        setLoading(true);
        window.location.assign(integrationUri);
    };

    useEffect(() => {
        if (publicToken?.length && !VALID_COINBASE) {
            setLoading(true);
            const userId = userContext.userState.user.userId;
            exchangeToken(userId, publicToken).then((res) => {
                logging.info('Exchange token success', res.data);
                // Refresh user context
                const fire_token = localStorage.getItem('fire_token');
                if (fire_token === null) {
                    userContext.userDispatch({
                        type: 'logout',
                        payload: initialUserState
                    });
                } else {
                    Validate((error, user) => {
                        if (error) {
                            logging.error(error);
                            userContext.userDispatch({
                                type: 'logout',
                                payload: initialUserState
                            });
                        } else if (user) {
                            userContext.userDispatch({
                                type: 'login',
                                payload: { user, fire_token }
                            });
                        }
                    });
                }
            });
        } else if (VALID_COINBASE) {
            setLoading(false);
        }
    }, [VALID_COINBASE, publicToken, userContext]);

    const coinbaseUserInfo =
        userContext.userState.user.coinbase?.userInfo?.data;

    return (
        <Card
            display="flex"
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
            width={400}
        >
            {!VALID_COINBASE ? (
                <>
                    <IconFrame size={20} type="error" icon={WarningSignIcon} />
                    <Heading size={700} marginTop={10}>
                        Let's get you connected
                    </Heading>
                    <Button
                        marginTop={15}
                        disabled={VALID_COINBASE}
                        appearance="primary"
                        onClick={() => redirectToIntegratePage()}
                        iconAfter={ArrowTopRightIcon}
                        isLoading={loading}
                    >
                        Start
                    </Button>
                </>
            ) : (
                <>
                    <IconFrame size={20} type="success" icon={TickCircleIcon} />
                    <Heading size={700} marginTop={10}>
                        Connected!
                    </Heading>
                    <Text size={400} marginTop={8}>
                        {coinbaseUserInfo?.name}
                    </Text>
                    <Text size={400} marginTop={4}>
                        {coinbaseUserInfo?.email}
                    </Text>
                </>
            )}
        </Card>
    );
};

export default CoinbaseIntegration;
