import React, { useContext } from 'react';
import { Navigate } from 'react-router-dom';
import logging from '../../config/logging';
import UserContext from '../../contexts/user';
import IRoute from '../../interfaces/route';

export interface IAuthRouteProps {
    children: any;
    route: IRoute;
}

export const ValidRoute: React.FunctionComponent<IAuthRouteProps> = (props) => {
    const { route, children } = props;

    const userContext = useContext(UserContext);

    const VALID_AUTH = userContext.userState.user._id !== '';
    const ONBOARDING_COMPLETE = userContext.userState.user.onboardingLevel === 5;

    if (route.requiresAuth && !VALID_AUTH) {
        logging.info('Unauthorized, redirecting.');
        return <Navigate to="/login" />;
    } else if (route.requiresIntegrations && !ONBOARDING_COMPLETE) {
        logging.info('Onboarding not complete, redirecting.');
        return <Navigate to="/onboarding" />;
    } else if (!route.requiresAuth && VALID_AUTH && ONBOARDING_COMPLETE) {
        logging.info('Authorized user, redirecting.');
        return <Navigate to="/dashboard" />;
    } else if (!route.requiresAuth && VALID_AUTH && !ONBOARDING_COMPLETE) {
        logging.info('Authorized user but onboarding not complete, redirecting.');
        return <Navigate to="/onboarding" />;
    } else {
        return <>{children}</>;
    }
};
