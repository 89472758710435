import logging from '../config/logging';
import { axiosRequest } from '../config/axios';

const NAMESPACE = 'coinbase';
const ROUTE = '/coinbase/setup';

export const exchangeToken = async (userId: string, publicToken: string) => {
    try {
        const response = await axiosRequest({
            method: 'POST',
            url: `${ROUTE}/exchange_public_token`,
            data: { publicToken, userId }
        });
        return await response.data;
    } catch (error) {
        logging.error(error, NAMESPACE);
        throw error;
    }
};
