import React, { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import UserContext from '../../contexts/user';
import firebase from 'firebase';
import { Authenticate, SignInWithSocialMedia as SocialMediaPopUp } from '../../modules/auth';
import logging from '../../config/logging';
import { Button, Pane, Text } from 'evergreen-ui';
import { Providers } from '../../config/firebase';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGoogle } from '@fortawesome/free-brands-svg-icons';
import styles from './styles.module.css';
import { v4 as uuidv4 } from 'uuid';
import { FULLY_ONBOARDED_LEVEL } from '../../constants/user';

interface IAuthButton {
    type: 'login' | 'create';
}

const AuthButton: React.FunctionComponent<IAuthButton> = ({ type }) => {
    const [authenticating, setAuthenticating] = useState<boolean>(false);
    const [error, setError] = useState<string>('');

    const userContext = useContext(UserContext);
    const navigate = useNavigate();

    const signInWithSocialMedia = (provider: firebase.auth.AuthProvider) => {
        if (error !== '') setError('');

        setAuthenticating(true);

        SocialMediaPopUp(provider)
            .then(async (result) => {
                logging.info(result);

                let user = result.user;

                if (user) {
                    const newUser = {
                        fuid: user.uid,
                        userId: uuidv4(),
                        name: user.displayName,
                        email: user.email
                    };

                    if (newUser.name) {
                        try {
                            let fire_token = await user.getIdToken();

                            Authenticate(newUser, fire_token, type, (error, _user) => {
                                if (error) {
                                    setError(error);
                                    setAuthenticating(false);
                                } else if (_user) {
                                    userContext.userDispatch({ type: 'login', payload: { user: _user, fire_token } });
                                    const ONBOARDING_COMPLETE = _user.onboardingLevel === FULLY_ONBOARDED_LEVEL;
                                    if (ONBOARDING_COMPLETE) {
                                        navigate('/dashboard');
                                    } else {
                                        navigate('/onboarding');
                                    }
                                }
                            });
                        } catch (error) {
                            setError('Invalid token.');
                            logging.error(error);
                            setAuthenticating(false);
                        }
                    } else {
                        setError('The identify provider is missing a display name.');
                        setAuthenticating(false);
                    }
                } else {
                    setError('The social media provider does not have enough information. Please try a different provider.');
                    setAuthenticating(false);
                }
            })
            .catch((error) => {
                logging.error(error);
                setAuthenticating(false);
                setError(error.message);
            });
    };
    return (
        <>
            <Pane>{error?.length > 0 && <Text>Invalid credentials</Text>}</Pane>

            <Button disabled={authenticating} onClick={() => signInWithSocialMedia(Providers.google)}>
                <span className={styles.companyIcon}>
                    <FontAwesomeIcon icon={faGoogle} />
                </span>
                Continue with Google
            </Button>
        </>
    );
};

export default AuthButton;
