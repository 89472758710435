export default interface IUser {
    _id: string;
    userId: string;
    fuid: string;
    email: string;
    name: string;
    onboardingLevel: number;
    maxInvestmentPerWeek: number;
    roundUpToNearest: number;
    plaidIntegrated: boolean;
    coinbaseIntegrated: boolean;
    plaid?: {
        accounts: any[];
    };
    coinbase?: {
        userInfo?: {
            data: any;
        };
    };
}

export const DEFAULT_USER: IUser = {
    _id: '',
    userId: '',
    fuid: '',
    email: '',
    name: '',
    plaidIntegrated: false,
    coinbaseIntegrated: false,
    onboardingLevel: 0,
    maxInvestmentPerWeek: 0,
    roundUpToNearest: 0
};

export const DEFAULT_FIRE_TOKEN = '';
