import React from 'react';
import AuthButton from '../components/AuthButton/authentication';
import { Pane, Button, Heading } from 'evergreen-ui';
import { useNavigate } from 'react-router-dom';
import { Card } from '../theme/custom-evergreen';

const Login: React.FunctionComponent<{}> = () => {
    const navigate = useNavigate();
    return (
        <Pane display="flex" alignItems="center" justifyContent="center" className="fullHeightWithNav" marginTop={-40}>
            <Card>
                <Heading size={600}>Sign in to Coinup</Heading>
                <Heading size={200}>See your growth and manage your account.</Heading>
                <Pane marginTop={30}>
                    <AuthButton type={'login'} />
                    <Button onClick={() => navigate('/signup', { replace: true })} appearance="minimal">
                        Don't have an account? Sign up
                    </Button>
                </Pane>
            </Card>
        </Pane>
    );
};

export default Login;
